import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import _ from "lodash";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";
import { initializeI18next } from "../methods";
import DOMPurify from "dompurify";
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;
const UK_TLD = process.env.REACT_APP_UK_TLD;
const CA_TLD = process.env.REACT_APP_CA_TLD;
const cookies = new Cookies();
const obj = qs.parse(window.location.search, { decode: false });

initializeI18next();

const customBackend = {
  type: 'backend',
  async read(language, namespace) {
    const url = `${process.env.REACT_APP_PG3_APP_DOMAIN}/language_text?lang=${language}`;
    console.log("Fetching translations from:", url);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
      const data = await response.json();
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string') {
          try {
            data[key] = DOMPurify.sanitize(value);
          } catch (sanitizeError) {
            console.error(`Sanitization error for key "${key}":`, sanitizeError);
          }
        }
      }
      console.log("Sanitization done", data);
      return data;
    } catch (error) {
      console.error('Error during API call or sanitization:', error);
      return {};
    }
  }
};



i18n
  // .use(customBackend)
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    fallbackLng: false,
    saveMissing: false,
    initImmediate: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true,
    },
    interpolation: {
      format: (value, format, lng) => {
        console.log('interrrrrrrrrrrrrrr', { value });
        if (format === 'sanitizeHTML') {
          return i18n.services.formatter.format(value, 'sanitizeHTML', lng);
        }
        return value;  // Default formatting
      }
    },
    backend: {
      loadPath: `${REACT_APP_PG3_APP_DOMAIN}/language_text?lang={{lng}}`,
      crossDomain: true,
    },
  });




export default i18n;
