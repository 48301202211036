import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExternalSitePrompt from "./ExternalSitePrompt";

function Privacy() {
  const { t, i18n } = useTranslation();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  return (
    <div className="my-lg-5 py-5 ">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl p-lg-5 px-4 pgs-text">
        <div className="pgs-title pgs-title__xl ff-v py-5">
          PureInsight<sup>&trade;</sup> Privacy Policy
        </div>

        <div className="pgs-heading__sub ff-m fw-bold text-dark fst-italic d-block">
          Last Updated: March 12, 2025
        </div>

        <div className="pgs-text my-5">
          <div>
            <p>
              This Privacy Policy governs the PureInsight™ online platform (“Site”), genetic analysis services, and related services (collectively, the “Services”) owned and operated by Pure Encapsulations, LLC, a Nestlé Health Science U.S. company, or its affiliates and subsidiaries (referred to herein as “Pure Encapsulations,” “we,” “us,” or “our” as applicable). Please read it carefully to learn how we collect, use, share, and otherwise process Personal Information, and to learn about your rights and choices regarding such Personal Information.
            </p>
            <p>
              By visiting or using the Services you agree to the collection, use, and disclosure of your Personal Information as described in this Privacy Policy. If you do not agree, please do not access or use the Services.
            </p>
          </div>
          <br />
        </div>

        {/* section 1 */}
        <div className="my-5 pgs-text">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">
            NOTICE AT COLLECTION
          </h2>
          <div className="row col-12 border ms-1">
            <div className="p-2">
              <p>
                We collect Personal Information as detailed here and in our Privacy Policy. The following serves as our notice at collection of Personal Information in accordance with applicable U.S. state privacy laws. As defined by applicable law, we do not “sell” or “share” Personal Information.
              </p>
              <p>
                We keep Personal Information as long as it is necessary or relevant for the practices described in this Privacy Policy. We also keep Personal Information as otherwise required by law. What this means in practice will vary between different types of information, and when we consider our approach we take into account ongoing business or legal needs for the information, for example in relation to tax, health and safety, and potential or actual disputes or investigations. If you wish to cancel your account or request that we no longer use your Personal Information to provide you services, please contact us through the means set forth in the “How to Contact Us” section.
              </p>
            </div>
            <div className="row col-12 col-lg-6 border-end px-0">
              <div className="p-2 fw-bold border-top border-bottom bg-privacy-grey d-flex justify-content-center flex-column" style={{ minHeight: '90px' }}>
                What categories of Personal Information do we collect?
              </div>
              <div className="p-2">
                <ul className="privacy" >
                  <li><b>Identifiers:</b> This includes your name, mailing address, email address, mobile and telephone phone number, IP address, or other similar identifiers.</li>

                  <li><b> <i> Any Personal Information Described in Subdivision (e) of Section 1798.80, including: </i></b> </li>

                  <li><b>Account Login Data.</b> Any data that is required to give you access to your specific account profile. Examples include your login ID/email address, screen name, password in unrecoverable form, and/or security question and answer.</li>

                  <li><b>Technical Information.</b> This includes your mobile advertising ID, web browser type and version, device characteristics, and operating system. This also includes data about the computer system or other technological device that you use to access the Services, such as the IP address used to connect your computer or device to the Internet and other online identifiers. If you access the Services via a mobile device such as a smartphone, the collected data will also include, where permitted, your phone’s unique device ID, and other similar mobile device data.</li>

                  <li><b>Marketing and Communications Information.</b> If you are a healthcare provider, this includes your preferences in receiving marketing and other types of communications from us.</li>

                  <li><b>Other Information You Provide to Us.</b> This includes the content of your communications with us.</li>

                  <li><b>Usage Information.</b> This includes how you interact with the Services. For example, if you use our Apps, we may look at how often you use the App and where you downloaded it.</li>

                  <li><b>Payment and Financial Information.</b> This includes your method of payment (e.g., bank details and credit, debit, or other payment card information), bank account number, and billing address. This also includes any data that we need to fulfill an order, or that you use to make a purchase, or other forms of payment (if such are made available).</li>

                  <li><b>Transactional Information.</b> When you make a purchase or return, we collect information about the transaction, such as product details, purchase price, and date and location of the transaction, and where applicable, the referring healthcare provider.</li>

                  <li><b>Demographic Data & Interests.</b> Any data that describes your demographic or behavioral characteristics. Examples include your date of birth, age range, geographic location (e.g., ZIP code), favorite products, hobbies and interests, and household or lifestyle data. If you are a healthcare provider, we may collect data about your practice.</li>

                  <li><b>Characteristics of Protected Classifications under California or Federal Law:</b> This includes your age and gender.</li>

                  <li><b>Commercial Information:</b> This includes information about the products or services purchased, obtained, considered, or returned, as well as other purchasing or consuming histories, behaviors or tendencies, and any information that you share with us about your experience of using our products and Services.</li>

                  <li><b>Internet or other Electronic Network Activity Information:</b> This includes information related to your browsing history, search history and other information regarding your interactions with the Services, including, without limitation, actions you perform (e.g., clicks, mouse movements, keystrokes, and entering and submitting information) on the Services. As you navigate through and interact with the Services, or our communications, we may use automatic data collection technologies to collect certain data about your device(s) and your actions. This includes data such as which links you click on, which pages or content you view and for how long, and other similar data and statistics about your interactions, such as content response times, download errors, and length of visits to certain pages, as well as operating system type and web browser type and version.</li>

                  <li><b>Geolocation Data:</b> This includes location information derived from your IP address.</li>

                  <li><b>Professional Information:</b> This data may include your income level.</li>

                  <li><b>Education Information:</b> This data may include your educational level.</li>

                  <li><b>Inferences:</b> We collect profile inferences that we draw from your information and web activity to create a personalized profile so we can better identify goods and services that may be of interest to you.</li>

                  <li><b>Sensitive Personal Information:</b> This includes health information such as prescription information, medical conditions, treatment, nutrition, supplement intake, dietary preferences, sexuality, weight and fitness-related goals/activities, and exercise habits and goals. This also includes your genetic data if you use our genetic analysis services and voluntarily disclose this information by uploading your raw genetic data file.</li>

                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 px-0">
              <div className="fw-bold border-top border-bottom bg-privacy-grey p-2 d-flex justify-content-center flex-column" style={{ minHeight: '90px' }}>
                What are the purposes for which we collect and use your Personal Information?
              </div>
              <div className="p-2">
                <ul className="privacy">
                  <li><b>To Communicate with You.</b> We use Personal Information (e.g., your contact information) to respond to your questions and comments when you communicate with us through links or pages through the Services, such as the "Contact Us" feature, and to send you administrative information (e.g., information regarding the Services and changes to our terms, conditions, and other policies).</li>

                  <li><b>To Process Your Transactions.</b> If you purchase products from us on or through the Services, we use your Personal Information, such as your contact information and payment information to process your purchase, confirm your order, and deliver the items to you.</li>

                  <li><b>To Administer the Services.</b> We use your Personal Information to administer the Services and carry out any other business activities.</li>

                  <li><b>To Provide You with Personalized Recommendations.</b> If you choose to complete assessments in the Services, we will use information that you provide (including health information if you provide it to us in this manner) to send you personalized product recommendations and other information that we believe may be of interest to you. We also use your Personal Information to allow you to participate in interactive features when you choose to do so. For example, we remember your login ID/email address or screen name so that you can quickly login the next time you visit the Services or so that you can easily retrieve the items you previously placed in your shopping cart.</li>

                  <li><b>To Perform Analyses.</b> We use your Personal Information to perform business analyses or for other purposes designed to improve the quality of our business and the Services we offer.</li>

                  <li><b>To Prevent and Detect Fraud and Other Crimes.</b> We use your Personal Information to help prevent and detect fraud and other crimes that might be committed using or against the Services. We may use your information to investigate possible violations of and enforce our contracts. We may also use your Personal Information to protect our or others’ rights, privacy, safety, or property, and/or that of our affiliates. We do this to protect our business assets and to comply with our legal obligations.</li>

                  <li><b>To Manage and Operate Our IT Systems.</b> We use your Personal Information to help us in troubleshooting, testing, maintaining, and protecting our IT systems, including our Services.</li>

                  <li><b>To Comply with Legal and Regulatory Obligations.</b> We use your Personal Information to comply with our legal and regulatory obligations, which arise from time to time. For instance, we may use your contact information to alert you if a product is recalled.</li>

                  <li><b>Email Newsletters and Promotions.</b> If you are a healthcare provider and have requested marketing communications, we will use your Personal Information, including your contact information, to send you our newsletters, promotions, product news, and updates about new features or enhancements to the Services through email.</li>
                </ul>
                <b><i>Additional Purposes: </i></b>
                <ul className="privacy">
                  <li><b>Aggregate or Anonymous Personal Information.</b> We use Personal Information you provide to us regarding the Services to provide product feedback to our customers in aggregated form. We may also use Personal Information in an aggregated non-specific format for analytical and demographic purposes.</li>

                  <li><b>As Otherwise Permitted by Law or as We May Notify You.</b> We may also use information you provide to us for other purposes as disclosed at the time you provide your information or otherwise with your consent.</li>
                </ul>

              </div>
            </div>
          </div>
        </div>

        {/* section 2 */}
        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">TYPES OF PERSONAL INFORMATION WE COLLECT</h2>
          <p>
            When information identifies or is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you or your household, we refer to it as “Personal Information,” which we collect from you and about you. This may include:
          </p>
          <table className="striped table-bordered hover privacy-table">
            <thead className="bg-secondary">
              <tr>
                <th className="w-50 text-black">Personal Information Categories</th>
                <th className=" text-black">Specific Personal Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Identifiers</td>
                <td>
                  <p>
                    We may collect your name, mailing address, email address, mobile and telephone phone number, IP address, or other similar identifiers.
                  </p>
                </td>
              </tr>
              <tr>
                <td>Any Personal Information Described in Subdivision (e) of Section 1798.80</td>
                <td>
                  We may collect the following:
                  <ul className="privacy">
                    <li><b>Account Login Data.</b> Any data that is required to give you access to your specific account profile. Examples include your login ID/email address, screen name, password in unrecoverable form, and/or security question and answer.</li>

                    <li><b>Technical Information.</b> This includes your mobile advertising ID, web browser type and version, device characteristics, and operating system. This also includes data about the computer system or other technological device that you use to access the Services, such as the IP address used to connect your computer or device to the Internet and other online identifiers. If you access the Services via a mobile device such as a smartphone, the collected data will also include, where permitted, your phone’s unique device ID, and other similar mobile device data.</li>

                    <li><b>Usage Information.</b> This includes how you interact with the Services. For example, if you use our Apps, we may look at how often you use the App and where you downloaded it.</li>

                    <li><b>Marketing and Communications Information.</b> If you are a healthcare provider, this includes your preferences in receiving marketing and other types of communications from us.</li>

                    <li><b>Payment and Financial Information.</b> This includes your method of payment (e.g., bank details and credit, debit, or other payment card information), bank account number, and billing address. This also includes any data that we need to fulfil an order, or that you use to make a purchase, or other forms of payment (if such are made available).</li>

                    <li><b>Transactional Information.</b> When you make a purchase or return, we collect information about the transaction, such as product details, purchase price, and date and location of the transaction, and where applicable, the referring healthcare provider.</li>

                    <li><b>Demographic Data & Interests.</b> Any data that describes your demographic or behavioral characteristics. Examples include your date of birth, age range, geographic location (e.g., ZIP code), favorite products, hobbies and interests, and household or lifestyle data. If you are a healthcare provider, we may collect data about your practice.</li>

                    <li><b>Other Information You Provide to Us.</b> This includes the content of your communications with us.</li>

                  </ul>
                </td>
              </tr>
              <tr>
                <td>Characteristics of Protected Classifications under California or Federal Law</td>
                <td>
                  <p>
                    We may collect your age and gender.
                  </p>
                </td>
              </tr>
              <tr>
                <td>Commercial Information</td>
                <td>
                  <p>
                    We may collect information about the products or services purchased, obtained, considered, or returned, as well as other purchasing or consuming histories, behaviors or tendencies, and any information that you share with us about your experience of using our products and Services.
                  </p>
                </td>
              </tr>
              <tr>
                <td>Internet or other Electronic Network Activity Information</td>
                <td>
                  <p>
                    This includes information related to your browsing history, search history and other information regarding your interactions with the Services, including, without limitation, actions you perform (e.g., clicks, mouse movements, keystrokes, and entering and submitting information) on the Services. As you navigate through and interact with the Services, or our communications, we may use automatic data collection technologies to collect certain data about your device(s) and your actions. This includes data such as which links you click on, which pages or content you view and for how long, and other similar data and statistics about your interactions, such as content response times, download errors, and length of visits to certain pages, as well as operating system type and web browser type and version.
                  </p>
                </td>
              </tr>
              <tr>
                <td>Geolocation Data</td>
                <td>We may collect location information derived from your IP address.  </td>
              </tr>
              <tr>
                <td>Professional Information</td>
                <td>This data may include your income level.  </td>
              </tr>
              <tr>
                <td>Education Information</td>
                <td>This data may include your education level.  </td>
              </tr>
              <tr>
                <td>Sensitive Personal Information</td>
                <td>
                  <div>
                    <p>
                      This includes health information such as prescription information, medical conditions, treatment, nutrition, supplement intake, dietary preferences, sexuality, weight and fitness-related goals/activities, and exercise habits and goals. This also includes your genetic data if you use our genetic analysis services and voluntarily disclose this information by uploading your raw genetic data file.
                    </p>
                    <p>
                      To the extent that Personal Information regarding medical conditions is stored, we may in certain circumstances be required to disclose it to third parties, including but not limited to, the following: the Food and Drug Administration pursuant to adverse events, public health authorities relative to preventing or controlling disease, as required by federal, state, or local law or as required pursuant to subpoena or court order.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* section 3 */}
        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">HOW WE COLLECT YOUR PERSONAL INFORMATION</h2>
          <p>
            There are several ways we may obtain information about you, including through (A) information you provide to us directly through both online and offline interactions; (B) information that we automatically collect when you use the Services; and (C) information we receive from third parties. This includes data that identifies you personally whether directly or indirectly.
          </p>
          <b>Information You Provide</b>
          <p>
            We collect information from you when you or someone acting on your behalf choose to share it with us. This may include when you create an account with or register a product purchase with us; contact us with questions or to provide feedback; when you fill out a questionnaire and submit your responses to us; or when you interact with us offline through in-person interactions.
          </p>
          <b>Information Automatically Collected</b>
          <p>
            Whenever you visit or interact with the Services, we, as well as third-party service providers, may use cookies, web beacons, pixel tags, or other technologies to automatically or passively collect information about your online activity. As you interact with our Services, we may automatically collect technical information about your device as well as usage information through these and other similar technologies. We may also collect certain information through these technologies when you visit other websites.
          </p>
          <b>Information Collected From Other Sources</b>
          <p>
            We may collect information about you from your healthcare provider when they invite you to use the Services, upload information on your behalf or add information to your analysis reports through the Services. We may combine the various types of information we collect about you and use it as described in this Privacy Policy.
          </p>
        </div>

        {/* section 4 */}
        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h2>
          <p>
            We may use the information we collect from and about you for a variety of business and commercial purposes, including:
          </p>
          <div>
            <p><b>Communicating with You.</b> We use Personal Information (e.g., your contact information) to respond to your questions and comments when you communicate with us through links or pages in the Services, such as the “Contact Us” feature, and to send you administrative information (e.g., information regarding the Services and changes to our terms, conditions, and other policies).</p>

            <p><b>Processing Your Transactions.</b> If you purchase products from us on or through the Services, we use your Personal Information, such as your contact information and payment information to process your purchase, confirm your order, and deliver the items to you.</p>

            <p><b>Administering the Services.</b> We use your Personal Information to administer the Services and carry out any other business activities.</p>

            <p><b>Providing You with Personalized Recommendations.</b> If you choose to complete assessments in the Services, we will use information that you provide to send you personalized product recommendations and other information that we believe may be of interest to you. We also use your Personal Information to allow you to participate in interactive features when you choose to do so. For example, we remember your login ID/email address or screen name so that you can quickly login the next time you visit the Services or so that you can easily retrieve the items you previously placed in your shopping cart.</p>

            <p><b>Performing Analyses.</b> We use your Personal Information to perform business analyses or for other purposes designed to improve the quality of our business and the Services we offer.</p>

            <p><b>Preventing and Detecting Fraud and Other Crimes.</b> We use your Personal Information to help prevent and detect fraud and other crimes that might be committed using or against the Services. We may use your Personal Information to investigate possible violations of and enforce our contracts. We may also use your Personal Information to protect our or others’ rights, privacy, safety or property, and/or that of our affiliates. We do this to protect our business assets and to comply with our legal obligations.</p>

            <p><b>Managing and Operating Our IT Systems.</b> We use your Personal Information to help us in troubleshooting, testing, maintaining, and protecting our IT systems, including our Services.</p>

            <p><b>Complying with Legal and Regulatory Obligations.</b> We use your Personal Information to comply with our legal and regulatory obligations, which arise from time to time. For instance, we may use your contact information to alert you if a product is recalled.</p>

            <p><b>Email Newsletters and Promotions.</b> If you are a healthcare provider and have requested marketing communications, we will use your Personal Information, including your contact information, to send you our newsletters, promotions, product news, and updates about new features or enhancements to the Services through email.</p>

            <p><b>Creating Aggregate or Deidentified Personal Information.</b>We use Personal Information (which may include health-related or sensitive data) you provide to us regarding the Services to provide product feedback to our customers in deidentified or aggregated form. We may also use deidentified or aggregate Personal Information, including health-related or sensitive data, to analyze general trends in the use of the Services. We will take reasonable steps to maintain and use the information in deidentified form and will not to attempt to reidentify this information. We may provide deidentified or aggregated information to third parties, our Affiliates, or our service providers. </p>

            <p><b>As Otherwise Permitted by Law or as We May Notify You.</b> We may also use information you provide to us for other purposes as disclosed at the time you provide your information or otherwise with your consent.</p>

          </div>
        </div>


        {/* section 5 */}
        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">TO WHOM WE DISCLOSE PERSONAL INFORMATION</h2>
          <p>
            We will disclose your Personal Information to the following types of entities:
          </p>
          <div>
            <p><b>Service Providers.</b> We may share your Personal Information with our service providers (such as vendors and consultants) that perform certain functions or services on our behalf to fulfill the services you request from us. These include companies that host our Services, manage databases, perform analyses, send communications for us, or provide certain other components of the Services. Our service providers are not authorized to use or disclose the Personal Information we share with them for any purpose other than performing services on our behalf.</p>

            <p><b>Healthcare Providers.</b> When you register for an account on the Site following an invitation from your healthcare provider, we will share your health information including your genetic data file and any questionnaire responses with your provider.</p>

            <p><b>Analytics Partners.</b> We may use third-party analytics tools to better understand who is using or how to improve the effectiveness of the Services and related content. For instance, we use analytics tools provided by Google to help us understand how people use the Services. If you wish to prevent your data from being used by Google Analytics, Google has developed the Google Analytics opt-out browser add-on available <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"> here</a>.</p>

            <p><b>Competent Governmental and Public Authorities.</b> We may share your Personal Information with governmental authorities if we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity or a violation of our Terms and Conditions (posted at the footer of our Site) or other contracts.</p>

            <p><b>Third Parties Pursuant to Legal Process.</b> We may share your Personal Information to the extent necessary to respond to subpoenas, court orders, or other legal process; in response to a request for cooperation from law enforcement or a government agency; or to otherwise comply with our other legal and regulatory obligations.</p>

            <p><b>Relevant Third Parties as Part of a Transaction.</b> To advance our lawful commercial interests, we may share your Personal Information with certain third parties (including our professional advisers) in connection with a corporate transaction, such as a sale, assignment, divestiture, merger, bankruptcy, consolidation, reorganization, liquidation, or other transfer of the business or its assets. When we do this, we will incorporate reasonable security and other measures to protect your Personal Information.</p>

            <p><b>Other Third Parties.</b> We may share your Personal Information when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, including to allow us to pursue available remedies and limit the damage we may sustain. We may also share your Personal Information with other third parties in certain circumstances. We will explain these circumstances to you at the point of information collection and, where we deem legally necessary, get your permission.</p>

            <p><b>Aggregated or De-Identified Information.</b> We may also share aggregated or de-identified information that cannot reasonably be used to identify you.</p>
          </div>
        </div>


        {/* section 6 */}
        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">MARKETING CHOICES</h2>
          <p>
            If you are a healthcare provider, you may receive marketing offers or solicitations from us through email from time to time. If you no longer prefer to receive marketing emails from us, please follow the opt-out instructions at the bottom of each marketing email. Please note that these requests may take up to ten (10) days to be effective.
          </p>
          <p>
            Your request not to receive marketing emails from us will not apply to messages that you request or that are informational. For example, we may contact you concerning any purchases you have made with us even if you opt out of receiving marketing emails.
          </p>
        </div>

        {/* section 7 */}
        <div className="my-5 text-break">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">ACCOUNT INFORMATION </h2>
          <p className="break-all">
            You may update and correct certain account information you provide to us at any
            time by logging into your account, emailing us at <a href="mailto:pureinsight@pureencapsulations.com">pureinsight@pureencapsulations.com</a>,
            or calling us at <b>1-800-753-2277</b>. If you wish to deactivate your account, please email us
            at <a href="mailto:pureinsight@pureencapsulations.com">pureinsight@pureencapsulations.com</a> or call us at <b>1-800-753-2277</b> but note that we may
            retain certain information as required by law or for lawful business purposes.
            We may also retain cached or archived copies of information about you.
          </p>
        </div>

        {/* section 8 */}
        <div className="my-5 text-break">
          {/* subsection 1 */}
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">U.S. PRIVACY RIGHTS AND REQUESTS</h2>
          <div>
            <b><u>Explanation of Applicable Privacy Rights</u></b>
          </div>
          <p>
            Depending on your U.S. state of residence, you may have certain rights in relation to your Personal Information, including:
          </p>
          <ul>
            <li className="my-3"><b>Right to Know:</b> You may have the right to request that we provide you with what Personal Information we have collected about you, including the categories of Personal Information, the categories of sources from which the Personal Information is collected, the business or commercial purpose for collecting, selling, or sharing Personal Information, the categories of third parties to whom we disclose Personal Information, and the specific pieces of Personal Information we have collected about you. Please note that we may not be required to respond to your requests “to know” or access specific pieces of Personal Information more than twice in any 12-month period.</li>

            <li className="my-3"><b>Right to Request Specific List of Third Parties:</b> You may have the right to request a list of the specific third parties with whom we have shared or sold your Personal Information. </li>

            <li className="my-3"><b>Right to Data Portability:</b> You may have the right to access your information in a portable format.</li>

            <li className="my-3"><b>Right to Delete:</b> You may have the right to request that we delete Personal Information that we have collected from you, subject to certain exceptions. Note that there are some reasons we will not be able to fully address your request, such as if we need to complete a transaction for you, to detect and protect against fraudulent and illegal activity, to exercise our rights, for our internal purposes, or to comply with a legal obligation.</li>

            <li className="my-3"><b>Right to Correct:</b> You may have the right to request that we correct inaccurate Personal Information that we may maintain about you, subject to appropriate verification.</li>

          </ul>

          {/* subsection 2 */}
          <div>
            <b><u>How to Submit a Request</u></b>
          </div>
          <div className="">
            <p>
              Please note that, where permitted under applicable law, we may decline a request if we are
              unable to verify your identity (or an agent’s authority to make the request) and confirm
              the Personal Information we maintain relates to you.
            </p>
            <p className="break-all">
              If you are interested in exercising one or more of the rights outlined above, please submit a request through our
              interactive webform available <b><a href="https://nestle-privacy.my.onetrust.com/webform/066970cb-5132-4b6c-b3a5-5cc52daf8a94/1de4423a-5a2f-4b4d-b518-bf15711e0def" target="_blank">here</a></b> or
              by calling us at <b><a href="tel:+18007532277"><b>1-800-753-2277</b></a></b>.
              We may take steps to verify your identity before responding to your request by asking you a series of questions about
              your previous interactions with us. Submitting a privacy rights request does not require you to create an account with us.
            </p>
            <p>We will not discriminate against you because you exercised your rights under this section of the Privacy Policy. </p>
          </div>

          {/* subsection 3 */}
          <div>
            <b><u>Agent Requests</u></b>
          </div>
          <p>Only you, or someone legally authorized to act on your behalf, may submit a request related to your Personal Information. You may also submit a request on behalf of your minor child. </p>
          <p>Depending on your U.S. state of residence, you may authorize someone to make a privacy rights request on your behalf (an authorized agent). Authorized agents will need to demonstrate that you’ve authorized them to act on your behalf or must demonstrate they have power of attorney pursuant to applicable probate law. We retain the right to request confirmation directly from you confirming that the agent is authorized to make such a request, or to request additional information to confirm the agent’s identity. An authorized agent is prohibited from using a consumer’s Personal Information, or any information collected from or about the consumer, for any purpose other than to fulfill the consumer’s requests, for verification, or for fraud prevention. If you are making a request as an authorized agent, please contact us using the information in the “How to Contact Us” section below, and provide your full name, address, email address, the full name of the person on whose behalf you are making the request, their address, email, phone number, as well as the written permission of the person on whose behalf you are acting to do so. </p>

          {/* subsection 4 */}
          <div>
            <b><u>Right to Appeal </u></b>
          </div>
          <p className="break-all">Depending on your U.S. state of residence, you may have the right to appeal a
            decision we have made in connection with your privacy rights request.
            You may appeal our decision by emailing us
            at <a href="mailto:NHScLegal@us.nestle.com">NHScLegal@us.nestle.com</a>.
            If you have concerns about the results of an appeal, you may contact the
            attorney general in the state where you reside.
          </p>

          {/* subsection 4 */}
          <div>
            <b><u>U.S. Data Protection Disclosures</u></b>
          </div>
          <p>In the past twelve (12) months, we have collected the categories of information disclosed above in the “Notice at Collection” section and disclosed those categories for the business purposes also listed in the “Notice at Collection” section.</p>
          <p><b>Use and Disclosure of Sensitive Personal Information:</b> To the extent that we collect, use, or share “Sensitive Personal Information” as that term is defined in applicable law, we limit our use or disclosure of the Sensitive Personal Information to permitted business purposes.</p>
        </div>



        {/* section 9 */}
        <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">OTHER DISCLOSURES </h2>

        <div className="my-5 text-break">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">CHILDREN’S PRIVACY</h2>
          <p>
            Our Services are intended for a general audience. We do not direct the Services to children under eighteen (18), nor do we knowingly solicit or collect any Personal Information from, children under the age of eighteen (18). If you are a parent or legal guardian and think that your child has given us data, you can contact us in writing or by email as provided below under the section titled “How to Contact Us.” Please mark your inquiries “Children’s Privacy Information Request.” If we learn that a minor has provided Personal Information through the Services, we will use reasonable efforts to remove such information from our files.
          </p>
        </div>

        <div className="my-5 text-break">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">MEDICAL CONTENT DISCLAIMER</h2>
          <p>
            Please understand that the contents and information on the Services and other Nestlé Health Science U.S. sites are for your informational use only and are not intended to be a substitute for professional medical advice, diagnosis or treatment or the independent clinical judgment of a health care professional or any applicable facility protocols. We suggest that you seek the advice of your physician or qualified health care provider with any questions you may have regarding a medical condition, illness, or treatment. Do not delay or disregard professional medical advice because of the content included on any of the Services.
          </p>
        </div>

        <div className="my-5 text-break">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">THIRD-PARTY WEBSITES</h2>
          <p>
            The Services may provide links to websites and applications operated by third parties. All of these websites and applications operate independently from us, and we do not control their privacy practices. We encourage you to review the privacy policies of any third-party websites and applications that you use to better understand their privacy practices.
          </p>
        </div>

        <div className="my-5 text-break">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">SECURITY OF YOUR INFORMATION</h2>
          <p>
            We maintain reasonable security procedures to help protect against loss, misuse, or unauthorized access, disclosure, alteration, or destruction of your Personal Information.
          </p>
          <p>
            Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal Information and privacy, we cannot guarantee or warrant the security of any information you disclose or transmit to us online and will not be responsible for the theft, destruction, or inadvertent disclosure of your Personal Information. If you prefer not to transmit your credit card number over the Internet, you can contact us using the toll-free number listed on the Services from which you want to order. If you have any questions regarding security, you can contact us through the means set forth in the “How to Contact Us” section.
          </p>
        </div>

        <div className="my-5 ">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">REVISIONS TO THIS PRIVACY POLICY</h2>
          <p>
            We reserve the right, at our sole discretion, to change, modify, add, remove, or otherwise revise portions of this Privacy Policy at any time. When we do, we will post the change(s) on our Services. Your continued use of our products and Services following the posting of changes to these terms means you accept these changes. If we change the Privacy Policy in a material or substantive way, we will provide appropriate notice to you, including providing notice to you fifteen (15) calendar days prior to the effective date. Such notice may be provided via a temporary banner on our Site, an email sent to users for whom we have an email address, or by temporarily noting “UPDATED” next to the Privacy Policy link on the footer of our Site.
          </p>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">HOW TO CONTACT US</h2>
          <p className="break-all">
            If you have any questions or concerns about this Privacy Policy or the practices described herein,
            you may contact us at <a href="mailto:pureinsight@pureencapsulations.com">pureinsight@pureencapsulations.com</a>,
            by calling 1-800-753-2277, or by mail to:
            <p className="mt-3">
              Pure Encapsulations, LLC <br />

              Nestlé Health Science U.S. <br />

              Attn: Legal Department <br />

              1007 US Highway 202/26 – JR2 <br />

              Bridgewater, NJ 08807
            </p>
          </p>
        </div>


      </div>
    </div>
  );
}

export default Privacy;